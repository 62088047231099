import React from "react";
import Hero from "../components/common/hero";
import useTranslations from "../components/useTranslations";
import HelmetHeader from "../components/helmetHeader";
import LocalizedLink from "../components/localizedLink";
import { StaticImage } from "gatsby-plugin-image";
import CtaSection from "../components/ctaSection";
import { graphql } from "gatsby";
import UseCasePreview from "../components/clients/useCasePreview";
import ClientTestimony from "../components/clients/clientTestimony";
import ClientLogo from "../components/clients/clientLogo";

const Clients = ({
  location,
  data: { page, testimonies, useCases, logos },
}) => {
  const { lang } = useTranslations();
  return (
    <>
      <HelmetHeader
        location={location}
        lang={lang}
        title={page.seo.metaTitle}
        metas={[{ name: "description", content: page.seo.metaDescription }]}
      />
      <Hero title={page.header?.title} subtitle={page.header?.subtitle} />
      <div className="w-full bg-white">
        <div className="relative max-w-5xl mx-auto">
          <div className="py-6">
            <div className="flex justify-center px-4 lg:px-0 mt-12 mb-12">
              <h3 className="text-4xl font-medium text-center">
                {page.useCasesTitle}
              </h3>
            </div>
            {useCases.nodes.map((useCase) => (
              <UseCasePreview useCase={useCase} />
            ))}
          </div>
        </div>
        <div className="bg-gray-100">
          <div className="max-w-5xl mx-auto pt-8 pb-24 px-4 lg:px-0 ">
            <div className="flex justify-center mt-12 mb-20">
              <h3 className="text-4xl font-medium">{page.testimoniesTitle}</h3>
            </div>
            <div className="grid gap-x-6 gap-y-8 lg:grid-cols-2">
              {testimonies.nodes.map((testimony) => (
                <ClientTestimony testimony={testimony} />
              ))}
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="max-w-5xl mx-auto pt-8 pb-24 px-4 lg:px-0 ">
            <div className="flex justify-center mt-12 mb-20">
              <h3 className="text-4xl font-medium">{page.logosTitle}</h3>
            </div>
            <div className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-x-12 gap-y-8 lg:-ml-4">
              {logos.nodes.map((logo) => (
                <ClientLogo logo={logo} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <CtaSection />
    </>
  );
};

export const query = graphql`
  query StrapiClientPage($locale: String) {
    page: strapiClientPage(locale: { eq: $locale }) {
      header {
        subtitle
        title
      }
      seo {
        metaTitle
        metaDescription
      }
      useCasesTitle
      testimoniesTitle
      logosTitle
    }
    testimonies: allStrapiClientTestimony(filter: { locale: { eq: $locale } }) {
      nodes {
        position
        testimonial
        link {
          href
          label
          target
          isExternal
        }
        logo {
          alternativeText
          url
        }
        tags {
          text
        }
      }
    }
    useCases: allStrapiClientUseCase(
      filter: { locale: { eq: $locale } }
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        title
        testimonial
        user
        job
        link {
          href
          label
          target
          isExternal
        }
        illustration {
          alternativeText
          url
        }
        order
      }
    }
    logos: allStrapiClientLogo(
      filter: { displayInClientsPage: { eq: true } }
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        link {
          href
          label
          target
          isExternal
        }
        logo {
          alternativeText
          url
        }
        order
      }
    }
  }
`;

export default Clients;

// import { graphql } from "gatsby"
// import ClientTestimony from '../components/clients/clientTestimony';
//
// const ClientPage = ({ data: { testimonies } }) => {
//   return (
//     <div className="bg-white">
//       {testimonies.nodes.map((testimony, index) =>
//         <ClientTestimony key={index} testimony={testimony} />
//       )}
//     </div>
//   )
// }
//
// export const query = graphql`
//     query ClientPageQuery($locale: String) {
//         testimonies: allStrapiClientTestimony(filter: {locale: {eq: $locale}}) {
//             nodes {
//                 name
//                 testimony {
//                     ...RichText
//                 }
//             }
//         }
//     }
// `
//
// export default ClientPage;
