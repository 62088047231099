import React from "react";

const ClientTestimony = ({ testimony }) => {
  return (
    <div className="flex bg-white flex-col rounded-lg shadow-md transition-all hover:-mt-1 hover:mb-1 hover:shadow-lg">
      <a
        href={testimony?.link?.href}
        target={testimony?.link?.target}
        rel="noopener noreferrer"
        className="block h-full"
      >
        <div className="flex-1 px-8 py-3 h-full flex flex-col items-center justify-between">
          <div>
            <div className="flex flex-col mt-3 gap-4 items-center">
              <img
                className="w-40"
                src={testimony?.logo?.url}
                alt={testimony?.logo?.alternativeText}
                placeholder="blurred"
              />
            </div>
            <div className="flex flex-col my-4 gap-3 items-center">
              <span className="text-sm text-center font-normal italic text-gray-500">
                “{testimony?.testimonial}”
              </span>
              <span className="text-base text-center text-gray-700">
                {testimony?.position}
              </span>
            </div>
          </div>
          <div className="flex items-center flex-wrap justify-center gap-3 mt-5 mb-4">
            {testimony?.tags.map((tag) => (
              <span className="inline-flex items-center rounded-md bg-cyan-100 px-4 py-1 text-sm font-medium text-cyan-800">
                {tag?.text}
              </span>
            ))}
          </div>
        </div>
      </a>
    </div>
  );
};

export default ClientTestimony;
