import React from "react";

const ClientLogo = ({ logo, extraClass }) => {
  return (
    <div className={`flex justify-center items-center px-8 py-1 ${extraClass}`}>
      <img
        className="w-full"
        src={logo?.logo?.url}
        alt={logo?.logo?.alternativeText}
      />
    </div>
  );
};

export default ClientLogo;
