import React from "react";
import LocalizedLink from "../localizedLink";

const UseCasePreview = ({ useCase }) => {
  return (
    <div className="relative overflow-hidden">
      <div className="relative pt-6 pb-16 sm:pb-16">
        <main className="mt-4">
          <div className="mx-auto max-w-5xl px-4 lg:px-0">
            <div className="lg:grid lg:grid-cols-12 lg:gap-10">
              <div className="text-left md:max-w-2xl md:mx-auto lg:col-span-7 lg:flex lg:items-center">
                <div>
                  <h4 className="text-3xl tracking-tight font-medium text-gray-900 sm:leading-none">
                    {useCase?.title}
                  </h4>
                  <div className="mt-12 lg:col-span-2 lg:m-0">
                    <div className="max-w-md sm:max-w-2xl lg:max-w-none pb-10 lg:py-12">
                      <blockquote>
                        <div>
                          <svg
                            className="h-8 w-8 text-cyan-600 opacity-25"
                            fill="currentColor"
                            viewBox="0 0 32 32"
                            aria-hidden="true"
                          >
                            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                          </svg>
                          <p className="mt-2 text-base text-gray-500 italic">
                            {useCase?.testimonial}
                          </p>
                        </div>
                        <footer className="mt-4">
                          <p className="text-base font-medium text-gray-500">
                            {useCase.user}, {useCase?.job}
                          </p>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                  <LocalizedLink
                    to={useCase?.link?.href}
                    className="mt-2 inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none"
                  >
                    {useCase?.link?.label}
                  </LocalizedLink>
                </div>
              </div>
              <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-5 flex items-center justify-center lg:justify-self-end">
                <img
                  className=""
                  src={useCase?.illustration?.url}
                  alt={useCase?.illustration?.alternativeText}
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default UseCasePreview;
